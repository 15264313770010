import { paths } from '@/routes-utils/paths.ts';
import { Button } from '@synoptic/ui-kit/button/button.tsx';
import { Input } from '@synoptic/ui-kit/input/input.tsx';
import { clsx } from 'clsx';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Form, PrefetchPageLinks, useNavigate } from 'react-router';
import { landingInput } from '../page.css.ts';
import { formContainer } from './form.css.ts';

type FormInputs = {
  email: string;
};

export const LoginEmailForm: FC<{
  className?: string;
}> = ({ className }) => {
  const { handleSubmit, formState, register } = useForm<FormInputs>();
  const navigate = useNavigate();

  const submit: SubmitHandler<FormInputs> = async (data) => {
    navigate(paths.landingSignup, {
      state: { email: data.email },
      viewTransition: true,
    });
  };
  return (
    <>
      {/* PrefetchPageLinks seems to not work without rendered Link or Form https://github.com/remix-run/react-router/discussions/12981 */}
      <Form
        className={clsx(formContainer, className)}
        onSubmit={handleSubmit(submit)}
        action={paths.landingSignup}
      >
        <Input
          className={landingInput}
          type={'email'}
          placeholder={'Enter your email'}
          required
          {...register('email', {
            required: true,
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Invalid email',
            },
          })}
          errorMessage={formState.errors.email?.message}
        />
        <Button size={'large'} type="submit">
          Continue
        </Button>
      </Form>
      <PrefetchPageLinks page={paths.landingSignup} />
    </>
  );
};
